<template>
  <div class="container--fluid pa-0">
    <the-header />
    <the-payment-page />
    <the-footer />
  </div>
</template>

<script>
import TheFooter from "../components/TheFooter.vue";
import TheHeader from "../components/TheHeader.vue";
import ThePaymentPage from "../components/ThePaymentPage.vue";
export default {
  name: "Checkout",
  components: { TheHeader, TheFooter, ThePaymentPage },
};
</script>

<style></style>
