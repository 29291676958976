<template>
  <div class="container--fluid pa-0">
    <the-header />
    <div class="container--fluid pa-0">
      <v-card flat color="primary" height="300" class="d-flex">
        <v-card
          flat
          color="secondary"
          height="89"
          class="border-r-0 ma-auto d-flex align-center justify-center px-10 py-4"
        >
          <p
            :class="$vuetify.breakpoint.xsOnly ? 'font-30' : 'font-40'"
            class="mb-0 line-h-62 font-weight--bold white--text letter-s-25 font-sofia"
          >
            Most Popular
          </p>
        </v-card>
      </v-card>
      <scrollactive class="h-menu" :offset="130">
        <a href="#popular-matches" class="scrollactive-item">
          Popular Matches
        </a>
        <a href="#clubs" class="scrollactive-item">Clubs</a>
        <a href="#faq" class="scrollactive-item">FAQs</a>
      </scrollactive>
      <!-- <div class="h-menu">
        <ul>
          <li>
            <a class="active" href="#popular-matches">Popular Matches</a>
          </li>
          <li><a href="#clubs">Clubs</a></li>
          <li><a href="#faq">FAQs</a></li>
        </ul>
      </div> -->
      <v-divider class="line"></v-divider>
      <div class="container pt-0" id="popular-matches">
        <div class="manchester-team">
          <v-row class="ma-0">
            <v-col cols="12" md="4" class="filter-and-sellticket">
              <filter-results type="popular" :id="1" />
              <sell-your-ticket-card />
            </v-col>
            <v-col cols="12" md="8" class="upcoming-team-matches bg-grey-light">
              <upcoming-fixtures-by-filter
                title="Most Popular Football Matches"
                :loading="false"
              />
            </v-col>
          </v-row>
        </div>
      </div>
      <div class="container--fluid bg-grey-light" id="tournament-info">
        <the-features />
      </div>
      <div class="container" id="clubs">
        <the-premier-league />
      </div>
      <div class="container" id="faq">
        <sell-your-ticket />
        <premier-club-info />
      </div>
    </div>
    <the-footer />
  </div>
</template>
<script>
import TheFooter from "../components/TheFooter.vue";
import TheHeader from "../components/TheHeader.vue";
import FilterResults from "@/components/FilterResults.vue";
import SellYourTicketCard from "@/components/SellYourTicketCard.vue";
import UpcomingFixturesByFilter from "@/components/UpcomingFixturesByFilter.vue";
import TheFeatures from "../components/TheFeatures.vue";
import ThePremierLeague from "../components/ThePremierLeague.vue";
import SellYourTicket from "../components/SellYourTicket.vue";
import PremierClubInfo from "../components/PremierClubInfo.vue";
export default {
  name: "MostPopularMatches",
  components: {
    TheHeader,
    TheFooter,
    UpcomingFixturesByFilter,
    FilterResults,
    SellYourTicketCard,
    TheFeatures,
    ThePremierLeague,
    SellYourTicket,
    PremierClubInfo,
  },
};
</script>
<style></style>
