<template>
  <div class="checkout--inner__wrap">
    <!-- <v-img
      :src="info.stadium_image"
      :lazy-src="info.stadium_image"
      aspect-ratio="1"
      gradient="to top right, rgba(100,115,201,.55), rgba(25,32,72,.7)"
    >
      <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular
            indeterminate
            color="grey lighten-5"
          ></v-progress-circular>
        </v-row>
      </template>
    </v-img> -->
    <div class="checkout-sec">
      <div
        class="team-details"
        :style="{ 'background-image': `url(${info.stadium_image})` }"
      >
        <div class="team-inner-data">
          <div class="name d-flex">
            <v-avatar class="mr-1" size="21">
              <img
                class="icon"
                :src="info.tournament_image"
                :alt="info.tournament_image"
              />
            </v-avatar>
            {{ info.tournament_name | capitalize }}
          </div>
          <h4 class="team-names">{{ info.name }}</h4>
          <p class="match-details ma-0">
            <span>
              {{ info.fixture_date | moment("dddd Do MMMM YYYY") }}
            </span>
            <span class="match-time"> | Kickoff : {{ info.start_time }}</span>
          </p>
          <!-- <div>
            <v-divider class="divide-line"></v-divider>
          </div> -->
        </div>
      </div>
      <div class="d-flex match-address">
        <span class="mr-1"> {{ info.stadium_name | capitalize }},</span>
        <span class="mr-1"> {{ info.stadium_city | capitalize }},</span>
        <span class="mr-1"> {{ info.stadium_country | capitalize }} </span>
        <v-avatar class="ml-1 mt-1" size="16">
          <countryflag rounded iso="gb" />
        </v-avatar>
        <!-- <img class="ml-2" src="@/assets/images/uk-flag.svg" alt="" /> -->
      </div>
      <div>
        <v-divider class="divide-line"></v-divider>
      </div>
      <div class="ticket-details">
        <v-row>
          <v-col cols="8" sm="8" class="stadium-details">
            <div class="table-header1">
              {{
                info.stadium_category
                  ? info.stadium_category
                  : "Ticket Category / Stand Name" | capitalize
              }}
              <!-- <v-tooltip
                content-class="doc-tooltip"
                color="#fff"
                text-color="#141715"
                top
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    size="17"
                    class="ml-3 mt-1 icon-anti"
                  >
                    mdi-information-outline
                  </v-icon>
                </template>
                <span class="content"
                  >Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry</span
                >
              </v-tooltip> -->
            </div>
            <p class="caption">
              {{
                info.stadium_category_description
                  ? info.stadium_category_description
                  : "Ticket, stand or category description" | capitalize
              }}
            </p>
          </v-col>
          <v-col cols="4" sm="4">
            <div class="d-flex cart-items">
              <v-btn
                :disabled="info.quantity < 2"
                :ripple="false"
                icon
                color="white"
                :class="info.quantity < 2 ? 'btn-plus' : 'btn-minus'"
                @click.prevent="decQty"
              >
                <v-icon
                  :class="info.quantity < 2 ? 'icon-plus' : 'icon-minus'"
                  color="black"
                >
                  mdi-minus
                </v-icon>
              </v-btn>
              <span class="cart-count">
                {{ info.quantity }}
              </span>
              <v-btn
                :ripple="false"
                icon
                color="white"
                class="btn-minus"
                @click.prevent="incQty"
              >
                <v-icon class="icon-minus" color="black"> mdi-plus </v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>
        <div>
          <v-divider class="divide-line"></v-divider>
        </div>
      </div>
      <div class="sub-total-wrap">
        <div class="total-card-wrap sub-total">
          <div class="total-ttl">Subtotal</div>
          <div class="">
            <div v-if="loading">
              <v-progress-circular indeterminate></v-progress-circular>
            </div>
            <div class="total-amt" v-else>
              {{ info.total_price | currency("£") }}
            </div>
          </div>
        </div>
        <div class="total-card-wrap sub-total">
          <div class="total-ttl">Booking Fee</div>
          <div class="">
            <div v-if="loading">
              <v-progress-circular indeterminate></v-progress-circular>
            </div>
            <div class="total-amt" v-else>
              {{ info.booking_fee | currency("£") }}
            </div>
          </div>
        </div>
      </div>
      <div class="total-card-wrap">
        <div class="total-ttl">Total</div>
        <div class="">
          <div v-if="loading">
            <v-progress-circular indeterminate></v-progress-circular>
          </div>
          <div class="total-amt" v-else>
            {{ info.grand_total | currency("£") }}
          </div>
        </div>
      </div>
      <!-- <div class="discription">
        <v-row>
          <v-col cols="12" sm="8" class="discription-1">
            <div class="d-flex payment-details align-center">
              Subtotal
              <v-tooltip
                content-class="doc-tooltip"
                color="#fff"
                text-color="#141715"
                top
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    size="17"
                    class="ml-3 mt-1 icon-anti"
                  >
                    mdi-information-outline
                  </v-icon>
                </template>
                <span class="content"
                  >Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry</span
                >
              </v-tooltip>
            </div>
          </v-col>
          <v-col v-if="loading" cols="12" sm="4" class="price">
            <v-progress-circular indeterminate></v-progress-circular>
          </v-col>
          <v-col v-if="!loading" cols="12" sm="4" class="price">
            {{ info.total_price | currency("£") }}
          </v-col>
        </v-row>
      </div> -->
      <!-- <div class="fee-details">
        <v-row>
          <v-col cols="12" sm="8" class="discription-2">
            <div class="d-flex payment-details align-center">
              Booking Fee
              <v-tooltip
                content-class="doc-tooltip"
                color="#fff"
                text-color="#141715"
                top
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    size="17"
                    class="ml-3 mt-1 icon-anti"
                  >
                    mdi-information-outline
                  </v-icon>
                </template>
                <span class="content"
                  >Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry</span
                >
              </v-tooltip>
            </div>
          </v-col>
          <v-col v-if="loading" cols="12" sm="4" class="price">
            <v-progress-circular indeterminate></v-progress-circular>
          </v-col>
          <v-col v-if="!loading" cols="12" sm="4" class="price">
            {{ info.booking_fee | currency("£") }}
          </v-col>
        </v-row>
      </div> -->
      <!-- <div class="d-flex align-center total-card">
        <v-card class="total-fee" elevation="0">
          <v-row class="ma-0">
            <v-col cols="12" sm="8" class="total-fee-details">
              <div class="d-flex total-details align-center">
                Total
                <v-tooltip
                  content-class="doc-tooltip"
                  color="#fff"
                  text-color="#141715"
                  top
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      size="17"
                      class="ml-3 mt-1 icon-anti"
                    >
                      mdi-information-outline
                    </v-icon>
                  </template>
                  <span class="content"
                    >Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry</span
                  >
                </v-tooltip>
              </div>
            </v-col>
            <v-col v-if="loading" cols="12" sm="4" class="price">
              <v-progress-circular indeterminate></v-progress-circular>
            </v-col>
            <v-col v-if="!loading" cols="12" sm="4" class="price">
              {{ info.grand_total | currency("£") }}
            </v-col>
          </v-row>
        </v-card>
      </div> -->
      <div class="any-ques">
        Any questions? Call us
        <span class="number">
          <a
            class="text-decoration-none text-color-white-200"
            href="tel:08712845277"
          >
            0871 284 5277
          </a>
        </span>
      </div>
    </div>
    <div class="d-flex align-center justify-center">
      <img class="pb-1" src="@/assets/images/gaurantee.png" alt="" />
    </div>
    <div class="d-flex align-center justify-center">
      <img class="pb-1" src="@/assets/images/cards.png" alt="" />
    </div>
  </div>
</template>
<script>
export default {
  name: "FixtueDetailsCardMolecule",
  data: () => ({}),
  props: {
    info: {
      Type: Object,
      required: true,
    },
  },
  computed: {
    loading() {
      return this.$store.state.app.wait;
    },
  },
  methods: {
    incQty() {
      this.info.quantity++;
      this.$emit("updateQuantity", this.info.quantity);
    },
    decQty() {
      this.info.quantity--;
      this.$emit("updateQuantity", this.info.quantity);
    },
  },
};
</script>
<style></style>
