<template>
  <div class="payment-wrap container">
    <div class="d-flex payment-header">
      <v-row>
        <v-col cols="12" md="8" class="payment-page">
          <div class="header-panel">
            <h2 class="page-name">Ticket Order Details</h2>
            <p class="page-details">
              Please check your ticket order details and confirm your order by
              going through the checkout process below. your tickets will be
              held till the timer hits zero.
            </p>
          </div>
        </v-col>
        <v-col cols="12" md="4" class="checkout-page">
          <div>
            <h3 class="name">Your tickets are held for:</h3>
            <div class="d-flex align-center justify-center timer-sec">
              <v-icon class="clock-five mr-2"
                >mdi-clock-time-five-outline</v-icon
              >
              <span class="time-clock">{{ date }} </span>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <div v-if="orderDetail && orderSlug" class="d-flex proceed-payment">
      <v-row>
        <v-col
          v-if="!orderDetail.billing_address_id"
          cols="12"
          md="8"
          class="checkout-form"
        >
          <CheckoutForm
            :isLoggedIn="$store.getters.token && $store.state.auth.loggedInUser"
            :isAuthenticationError="authHasError"
            @signin="signInUser"
            @submitCheckoutForm="updateOrderDetails"
            :orderDetail="orderDetail"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
          fixed-top
          class="checkout-wrap"
          v-if="!$vuetify.breakpoint.xsOnly"
        >
          <FixtueDetailsCard
            :info="orderDetail"
            @updateQuantity="updateOrderQty"
          />
        </v-col>
      </v-row>
      <v-card class="pa-5 primary" v-if="orderDetail.billing_address_id">
        <h1 class="text-center">This ticket is already purchased</h1>
        <v-btn @click="createPaymentLink">Make the Payment</v-btn>
        <div class="text-center any-ques">
          Any questions? Call us
          <span class="number">
            <a
              class="text-decoration-none text-color-white-200"
              href="tel:08712845277"
            >
              0871 284 5277
            </a>
          </span>
        </div>
      </v-card>
    </div>
    <div v-if="!orderDetail">
      <v-row>
        <v-col cols="12" md="6">
          <v-skeleton-loader
            v-bind="{ boilerplate: false }"
            v-for="i in 10"
            :key="i"
            type="image"
          ></v-skeleton-loader>
        </v-col>

        <v-col cols="12" md="6">
          <v-skeleton-loader
            v-bind="{ boilerplate: false }"
            type="card,list-item-avatar-three-line, actions"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import CheckoutForm from "@/components/PaymentMolecules/CheckoutFormGuest.vue";
import FixtueDetailsCard from "@/components/PaymentMolecules/FixtureDetailsCard.vue";
export default {
  name: "ThePaymentPage",
  components: {
    CheckoutForm,
    FixtueDetailsCard,
  },
  data: () => ({
    date: null,
    authHasError: false,
  }),
  watch: {
    "$route.params": {
      handler: function () {
        this.retrieveOrder();
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.countdownSession();
    this.fetchUsersDetails();
  },
  computed: {
    timer() {
      return new Date(JSON.parse(localStorage.getItem("ordrexp")));
    },
    orderSlug() {
      return this.$store.state.orders.orderSlug
        ? this.$store.state.orders.orderSlug
        : this.$route.params.orderSlug;
    },
    orderDetail() {
      return this.$store.state.orders.orderDetail;
    },
  },
  methods: {
    countdownSession() {
      setInterval(() => {
        let now = new Date().getTime();

        // Find the distance between now and the count down date
        let distance = this.timer - now;

        // Time calculations for days, hours, minutes and seconds
        // let days = Math.floor(distance / (1000 * 60 * 60 * 24));
        // let hours = Math.floor(
        //   (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        // );
        let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        let seconds = Math.floor((distance % (1000 * 60)) / 1000);
        if (minutes < 0 && seconds < 0) {
          this.$store.dispatch("orders/RESET", null);
        }
        // days + "d " + hours + "h " + minutes + "m " + seconds + "s "
        minutes = minutes > 9 ? minutes : `0${minutes}`;
        seconds = seconds > 9 ? seconds : `0${seconds}`;
        this.date = `${minutes} : ${seconds}`;
      }, 1000);
    },
    async retrieveOrder() {
      // if (this.$store.getters.token && this.$store.state.auth.loggedInUser) {
      //   await this.$store.dispatch("orders/RETRIEVE_ORDER_FOR_LOGGEDIN_USER", {
      //     slug: this.orderSlug,
      //   });
      // }
      await this.$store.dispatch("orders/GET_GUEST_ORDER_DETAILS", {
        slug: this.orderSlug,
      });
    },
    async updateOrderDetails(form) {
      if (this.$store.getters.token && this.$store.state.auth.loggedInUser) {
        await this.$store.dispatch("orders/ALLOCATE_USER_ADDRESS", {
          slug: this.orderSlug,
        });
        await this.$store
          .dispatch("orders/CONFIRM_ADDRESS_ORDER_FOR_REGISTERED_USER", form)
          .then(() => {
            this.createPaymentLink();
          });
      } else {
        await this.$store
          .dispatch("orders/CONFIRM_ADDRESS_ORDER", form)
          .then(() => {
            this.createPaymentLink();
          });
      }
    },
    async createPaymentLink() {
      await this.$store.dispatch("orders/CREATE_PAYMENT_LINK", {
        slug: this.orderSlug,
      });
    },
    async updateOrderQty(quantity) {
      if (this.$store.getters.token && this.$store.state.auth.loggedInUser) {
        await this.$store
          .dispatch("orders/UPDATE_ORDER_QUANTITY_FOR_REGISTERED_USER", {
            slug: this.orderSlug,
            quantity,
          })
          .finally(() => {
            this.retrieveOrder();
          });
      } else {
        await this.$store
          .dispatch("orders/UPDATE_ORDER_QUANTITY", {
            slug: this.orderSlug,
            quantity,
          })
          .then(() => {
            this.retrieveOrder();
          });
      }
    },
    async signInUser(payload) {
      await this.$store.dispatch("auth/LOGIN", payload).catch(() => {
        this.authHasError = true;
      });
    },
    async fetchUsersDetails() {
      if (this.$store.getters.token && this.$store.state.auth.loggedInUser) {
        await this.$store.dispatch("auth/GET_LOGGEDIN_USER_INFO");
        await this.$store.dispatch("users/GET_LOGGEDIN_USER_ADDRESS");
      }
    },
  },
};
</script>

<style></style>
