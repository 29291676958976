<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <div class="payment-sec">
      <template v-if="!this.$store.state.auth.loggedInUser">
        <div v-if="!showLoginForm" class="guest-sec">
          <div class="guest-sec-wrap">
            <div
              class="d-flex align-center justify-center guest-verify"
              :class="showLoginForm ? 'mb-6' : ''"
            >
              <img src="@/assets/images/lock.svg" alt="" class="guest_lock" />
              <p class="acc-with-us mb-0 dark">
                Do you have an account with us?
              </p>
            </div>
            <div class="d-flex align-center guest-signin">
              <v-btn
                outlined
                solo
                elevation="0"
                class="d-flex align-center justify-center btn-signin"
                @click.stop="showLoginForm = !showLoginForm"
              >
                Sign In
                <img
                  src="@/assets/images/right-arrow.svg"
                  alt=""
                  class="ml-2"
                />
              </v-btn>
            </div>
          </div>
        </div>
        <div
          v-if="showLoginForm"
          class="guest-sec"
          :class="isAuthenticationError ? 'error-hightlight' : ''"
        >
          <div
            class="d-flex align-center"
            :class="
              isAuthenticationError ? 'guest-verify' : 'justify-space-between'
            "
          >
            <template v-if="!isAuthenticationError">
              <div class="d-flex align-center justify-center guest-verify mb-6">
                <img src="@/assets/images/lock.svg" alt="" class="guest_lock" />
                <p class="acc-with-us mb-0 dark">
                  Do you have an account with us?
                </p>
              </div>
            </template>
            <template v-if="isAuthenticationError">
              <img src="@/assets/images/close.svg" alt="" class="guest_lock" />
              <p class="acc-with-us mb-0 dark">
                We’re sorry, those details didn’t match our records
              </p>
            </template>
          </div>
          <div v-if="isAuthenticationError" class="guest-forgot-info">
            Please check the details and try again. Alternatively, you can
            <a href="" class="reset">reset your password</a> or create an
            account at the end of the checkout process.
          </div>
          <v-form ref="loginForm" v-model="loginFormValid" lazy-validation>
            <div class="guest-signin guest-signin-new">
              <v-text-field
                v-model="loginPayload.email"
                :rules="[(v) => !!v || 'Email address is required']"
                hide-details="auto"
                placeholder="Email Address"
                outlined
                solo
                flat
                class="guest-ip mr-3"
              >
              </v-text-field>
              <v-text-field
                v-model="loginPayload.password"
                type="password"
                :rules="[(v) => !!v || 'Password is required']"
                hide-details="auto"
                placeholder="Password"
                outlined
                solo
                flat
                class="guest-ip mr-3"
              ></v-text-field>
              <v-btn
                :loading="loading"
                outlined
                solo
                elevation="0"
                class="d-flex align-center justify-center btn-signin"
                @click="submitLoginReq"
              >
                Sign In
                <img
                  src="@/assets/images/right-arrow.svg"
                  alt=""
                  class="ml-2"
                />
              </v-btn>
            </div>
          </v-form>
          <router-link
            to="/password/reset"
            class="text-capitalize px-0 forgot-text"
          >
            Forgot your password?
          </router-link>
        </div>
      </template>
      <!-- BASIC DETAILS -->
      <div class="sec-1">
        <div class="d-flex align-center heading-sec">
          <v-btn
            :ripple="false"
            color="primary"
            class="chip-btn"
            x-small
            elevation="0"
            fab
          >
            1.
          </v-btn>
          <h4 class="sec-name">Your Details</h4>
        </div>
        <div class="sec-details">
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="form.first_name"
                :rules="nameRules"
                hide-details="auto"
                placeholder="First Name*"
                outlined
                solo
                flat
                class="cart-input"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="form.last_name"
                :rules="nameRules"
                hide-details="auto"
                placeholder="Last Name*"
                outlined
                solo
                flat
                class="cart-input"
              ></v-text-field>
            </v-col>
          </v-row>
        </div>
        <div class="email-sec">
          <v-row>
            <v-col cols="12" sm="12">
              <v-text-field
                v-model="form.email"
                :rules="emailRules"
                hide-details="auto"
                placeholder="Email Address*"
                outlined
                solo
                flat
                class="cart2-input"
              >
                <template v-slot:prepend-inner>
                  <v-icon color="#D3D5D7" class="mr-3 ml-5 mt-1"
                    >mdi-email-outline</v-icon
                  >
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </div>
        <div class="num-details">
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="form.phone"
                :rules="[(v) => !!v || 'Phone number is required!']"
                hide-details="auto"
                placeholder="Phone Number*"
                outlined
                solo
                flat
                class="cart2-input"
              >
                <template v-slot:prepend-inner>
                  <v-icon color="#D3D5D7" class="mr-3 ml-5 mt-1"
                    >mdil-phone</v-icon
                  >
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="form.mobile"
                hide-details="auto"
                placeholder="Mobile Number*"
                outlined
                solo
                flat
                class="cart2-input"
              >
                <template v-slot:prepend-inner>
                  <img
                    color="#D3D5D7"
                    class="mr-3 ml-5 mt-1"
                    src="../../assets/images/smartphone.png"
                    alt=""
                  />
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </div>
        <div class="spl-req-sec">
          <v-row>
            <v-col cols="12" sm="12">
              <v-text-field
                v-model="form.special_request"
                hide-details="auto"
                placeholder="Special Requests"
                outlined
                solo
                flat
                class="cart-input"
              ></v-text-field>
            </v-col>
          </v-row>
        </div>
      </div>
      <!-- BILLING INFORMATION -->
      <div class="sec-2">
        <div class="d-flex align-center heading-sec">
          <v-btn
            :ripple="false"
            color="primary"
            class="chip-btn"
            x-small
            elevation="0"
            fab
          >
            2.
          </v-btn>
          <h4 class="sec-name">Billing Address</h4>
        </div>
        <!-- billing address list -->
        <template v-if="billingAddresses.length">
          <v-row class="ma-0 pb-4">
            <v-col
              v-for="(address, index) in billingAddresses"
              :key="address.id + index"
              cols="12"
              md="4"
              class="py-0 pl-0 ma-0 pb-4"
            >
              <v-row
                class="ma-0 address-card"
                :class="
                  form.billing_address_id === address.id ? 'highlight' : ''
                "
              >
                <v-col cols="12" md="10" class="pa-0">
                  <p class="mb-0 add-info">
                    {{ address.address_line_one | capitalize }}
                    {{ address.address_line_two | capitalize }}
                  </p>
                  <p class="mb-0 add-info">
                    {{ address.city_name | capitalize }}
                  </p>
                  <p class="mb-0 add-info">
                    {{ address.postcode.toUpperCase() }}
                  </p>
                  <p class="mb-0 add-info">
                    {{ address.country_name | capitalize }}
                  </p>
                </v-col>
                <v-col cols="12" md="2" class="pa-0">
                  <v-chip class="add-chip">
                    {{
                      addressTypeList[address.address_type - 1].name
                        | capitalize
                    }}
                  </v-chip>
                  <v-checkbox
                    @change="
                      selectBillingAddress(address);
                      showBillingAddressForm = false;
                    "
                    off-icon="mdi-checkbox-blank-circle-outline"
                    hide-details="auto"
                    class="mt-0 pt-0 shrink address-check"
                    :on-icon="
                      address.id === form.billing_address_id
                        ? 'mdi-check-circle-outline'
                        : 'mdi-checkbox-blank-circle-outline'
                    "
                    :ripple="false"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <div>
            <v-btn
              :ripple="false"
              outlined
              solo
              depressed
              class="btn-address"
              @click="
                form.billing_address_id = null;
                showBillingAddressForm = !showBillingAddressForm;
              "
            >
              {{ !showBillingAddressForm ? "+ Add" : "- Close" }} New Billing
              Address
            </v-btn>
          </div>
        </template>
        <!-- end billing address list  -->
        <template v-if="!billingAddresses.length || showBillingAddressForm">
          <div class="country-sec">
            <v-row>
              <v-col>
                <v-select
                  v-model="form.billing_address_type"
                  :rules="[
                    (v) => !!v || 'You must enter city name to continue!',
                  ]"
                  hide-details="auto"
                  :items="addressTypeList"
                  placeholder="-- Select address type --"
                  item-text="name"
                  item-value="id"
                  solo
                  flat
                  outlined
                  class="cart-input"
                ></v-select>
              </v-col>
            </v-row>
            <div v-if="form.billing_address_type === 2">
              <v-row>
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="form.billing_company_name"
                    :rules="[
                      (v) => !!v || 'You must enter company name to continue!',
                    ]"
                    hide-details="auto"
                    placeholder="Company Name*"
                    outlined
                    solo
                    flat
                    class="cart2-input"
                  >
                    <template v-slot:prepend-inner>
                      <v-icon color="#D3D5D7" class="mr-3 ml-5 mt-1"
                        >mdil-home</v-icon
                      >
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" md="6" sm="12">
                  <v-text-field
                    v-model="form.billing_company_start_time"
                    :rules="[
                      (v) => !!v || 'You must enter start time to continue!',
                    ]"
                    hide-details="auto"
                    placeholder="Company Start Time*"
                    outlined
                    solo
                    flat
                    class="cart2-input"
                  >
                    <template v-slot:prepend-inner>
                      <v-icon color="#D3D5D7" class="mr-3 ml-5 mt-1"
                        >mdil-clock</v-icon
                      >
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="6" md="6" sm="12">
                  <v-text-field
                    v-model="form.billing_company_end_time"
                    hide-details="auto"
                    placeholder="Company End Time"
                    outlined
                    solo
                    flat
                    class="cart2-input"
                  >
                    <template v-slot:prepend-inner>
                      <v-icon color="#D3D5D7" class="mr-3 ml-5 mt-1"
                        >mdil-clock</v-icon
                      >
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
            </div>
            <v-row>
              <v-col cols="12" sm="12">
                <v-text-field
                  v-model="form.billing_address_1"
                  :rules="[
                    (v) => !!v || 'You must enter valid address to continue!',
                  ]"
                  hide-details="auto"
                  placeholder="Address*"
                  outlined
                  solo
                  flat
                  class="cart2-input"
                >
                  <template v-slot:prepend-inner>
                    <v-icon color="#D3D5D7" class="mr-3 ml-5 mt-1"
                      >mdil-map-marker</v-icon
                    >
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="form.billing_city_name"
                  :rules="[
                    (v) => !!v || 'You must enter city name to continue!',
                  ]"
                  hide-details="auto"
                  placeholder="City / Town*"
                  outlined
                  solo
                  flat
                  class="cart-input"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <!-- <v-select
                v-model="form.billing_state"
                hide-details="auto"
                :items="items"
                :readonly="form.billing_country_name !== 'USA'"
                placeholder="[In the US Only]"
                solo
                flat
                outlined
                class="cart-input"
              ></v-select> -->
                <v-text-field
                  v-model="form.billing_state"
                  :rules="[(v) => !!v || 'You must enter state to continue!']"
                  hide-details="auto"
                  placeholder="State*"
                  outlined
                  solo
                  flat
                  class="cart-input"
                ></v-text-field>
              </v-col>
            </v-row>
          </div>
          <div class="Address-details">
            <v-row>
              <v-col cols="12" sm="6">
                <v-select
                  hide-details="auto"
                  :rules="[
                    (v) => !!v || 'You must select country to continue!',
                  ]"
                  :items="countryList"
                  placeholder="Select Country*"
                  item-text="name"
                  solo
                  flat
                  outlined
                  class="cart-input"
                  return-object
                  @change="selectedBillingCountry"
                >
                </v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="form.billing_postcode"
                  :rules="[
                    (v) => !!v || 'You must enter postcode or zip to continue!',
                  ]"
                  hide-details="auto"
                  placeholder="Postcode / ZIP"
                  outlined
                  solo
                  flat
                  class="cart-input"
                ></v-text-field>
              </v-col>
            </v-row>
          </div>
        </template>
        <div class="map-sec">
          <div
            class="delivery-confirm d-flex align-center justify-space-between px-10 py-6 my-5"
          >
            <div>Should we deliver to the above address?</div>
            <v-radio-group
              class="mt-0 pt-0"
              hide-details="auto"
              v-model="isShippingRequired"
              row
            >
              <v-radio
                label="Yes"
                :ripple="false"
                :value="false"
                color="#070B32"
                hide-details="auto"
              ></v-radio>
              <v-radio
                label="No"
                :value="true"
                :ripple="false"
                color="#070B32"
                hide-details="auto"
              ></v-radio>
            </v-radio-group>
          </div>
        </div>
      </div>
      <!-- DELIVARY INFORMATION -->
      <div class="sec-3">
        <template v-if="isShippingRequired">
          <div class="d-flex align-center heading-sec">
            <v-btn
              :ripple="false"
              color="primary"
              class="chip-btn"
              x-small
              elevation="0"
              fab
              >3.</v-btn
            >
            <h4 class="sec-name">Delivery Information</h4>
          </div>
          <template v-if="deliveryAddresses.length">
            <v-row class="ma-0 pb-4">
              <v-col
                v-for="(address, index) in deliveryAddresses"
                :key="address.id + index"
                cols="12"
                md="4"
                class="py-0 pl-0 ma-0 pb-4"
              >
                <v-row
                  class="ma-0 address-card"
                  :class="
                    form.shipping_address_id === address.id ? 'highlight' : ''
                  "
                >
                  <v-col cols="12" md="10" class="pa-0">
                    <p class="mb-0 add-info">
                      {{ address.address_line_one | capitalize }}
                      {{ address.address_line_two | capitalize }}
                    </p>
                    <p class="mb-0 add-info">
                      {{ address.city_name | capitalize }}
                    </p>
                    <p class="mb-0 add-info">
                      {{ address.postcode.toUpperCase() }}
                    </p>
                    <p class="mb-0 add-info">
                      {{ address.country_name | capitalize }}
                    </p>
                  </v-col>
                  <v-col cols="12" md="2" class="pa-0">
                    <v-chip class="add-chip">
                      {{
                        addressTypeList[address.address_type - 1].name
                          | capitalize
                      }}
                    </v-chip>
                    <v-checkbox
                      @change="
                        selectShippingAddress(address);
                        showShippingAddressForm = false;
                      "
                      off-icon="mdi-checkbox-blank-circle-outline"
                      hide-details="auto"
                      class="mt-0 pt-0 shrink address-check"
                      :on-icon="
                        address.id === form.shipping_address_id
                          ? 'mdi-check-circle-outline'
                          : 'mdi-checkbox-blank-circle-outline'
                      "
                      :ripple="false"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <div>
              <v-btn
                :ripple="false"
                outlined
                solo
                depressed
                class="btn-address"
                @click="
                  showShippingAddressForm = !showShippingAddressForm;
                  form.shipping_address_id = null;
                "
              >
                {{ !showShippingAddressForm ? "+ Add" : "- Close" }} New
                Shipping Address
              </v-btn>
            </div>
          </template>
          <template v-if="!deliveryAddresses.length || showShippingAddressForm">
            <div class="add-type">
              <v-row>
                <v-col cols="12" sm="12">
                  <v-select
                    v-model="form.shipping_address_type"
                    :rules="[
                      (v) => !!v || 'You must enter city name to continue!',
                    ]"
                    hide-details="auto"
                    :items="addressTypeList"
                    placeholder="-- Select address type --"
                    item-text="name"
                    item-value="id"
                    solo
                    flat
                    outlined
                    class="cart-input"
                  ></v-select>
                </v-col>
              </v-row>
            </div>
            <div class="sec-details">
              <v-row>
                <v-col cols="12" md="6" sm="6">
                  <v-text-field
                    v-model="form.address_first_name"
                    :rules="nameRules"
                    hide-details="auto"
                    placeholder="First Name*"
                    outlined
                    solo
                    flat
                    class="cart-input"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" sm="6">
                  <v-text-field
                    v-model="form.address_last_name"
                    :rules="nameRules"
                    hide-details="auto"
                    placeholder="Last Name*"
                    outlined
                    solo
                    flat
                    class="cart-input"
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>
            <div class="sec-details">
              <v-row v-if="form.shipping_address_type === 2">
                <v-col cols="12" md="12" sm="12">
                  <v-text-field
                    v-model="form.company_name"
                    :rules="[
                      (v) => !!v || 'You must enter company name to continue!',
                    ]"
                    hide-details="auto"
                    placeholder="Company Name*"
                    outlined
                    solo
                    flat
                    class="cart2-input"
                  >
                    <template v-slot:prepend-inner>
                      <v-icon color="#D3D5D7" class="mr-3 ml-5 mt-1"
                        >mdil-office</v-icon
                      >
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6" sm="12">
                  <v-text-field
                    v-model="form.company_start_time"
                    :rules="[
                      (v) => !!v || 'You must enter start time to continue!',
                    ]"
                    hide-details="auto"
                    placeholder="Company Start Time*"
                    outlined
                    solo
                    flat
                    class="cart2-input"
                  >
                    <template v-slot:prepend-inner>
                      <v-icon color="#D3D5D7" class="mr-3 ml-5 mt-1"
                        >mdil-clock</v-icon
                      >
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6" sm="12">
                  <v-text-field
                    v-model="form.company_end_time"
                    hide-details="auto"
                    placeholder="Company End Time"
                    outlined
                    solo
                    flat
                    class="cart2-input"
                  >
                    <template v-slot:prepend-inner>
                      <v-icon color="#D3D5D7" class="mr-3 ml-5 mt-1"
                        >mdil-clock</v-icon
                      >
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
            </div>
            <div class="sec-details">
              <v-row v-if="form.shipping_address_type > 2">
                <v-col cols="12" md="12" sm="12">
                  <v-text-field
                    v-model="form.boarding_name"
                    :rules="[
                      (v) => !!v || 'You must enter hotel name to continue!',
                    ]"
                    hide-details="auto"
                    :placeholder="`${
                      form.shipping_address_type === 3
                        ? 'Hotel'
                        : form.shipping_address_type === 4
                        ? 'Hostel'
                        : 'Apartment'
                    } Name*`"
                    outlined
                    solo
                    flat
                    class="cart-input"
                  >
                    <template v-slot:prepend-inner>
                      <v-icon color="#D3D5D7" class="mr-3 ml-5 mt-1"
                        >mdil-domain</v-icon
                      >
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="6" md="6" sm="12">
                  <v-menu
                    ref="checkInMenu"
                    v-model="checkInMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    class="cart2-input"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="dateUkFormat(form.checkin_time)"
                        :rules="[
                          (v) =>
                            !!v || 'You must enter start time to continue!',
                        ]"
                        hide-details="auto"
                        solo
                        outlined
                        flat
                        v-bind="attrs"
                        v-on="on"
                        placeholder="Check-out date"
                        class="cart2-input"
                      >
                        <template slot="prepend-inner">
                          <img
                            class="mr-3 ml-5 mt-1 calendar"
                            src="../../assets/images/calendar.svg"
                          />
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker
                      v-model="form.checkin_time"
                      no-title
                      scrollable
                      class="cart2-input"
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        :ripple="false"
                        text
                        color="primary"
                        @click="checkInMenu = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        :ripple="false"
                        color="primary"
                        @click="$refs.checkInMenu.save(form.checkin_time)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                  <!-- <v-text-field
                  v-model="form.checkin_time"
                  :rules="[
                    (v) => !!v || 'You must enter start time to continue!',
                  ]"
                  hide-details="auto"
                  placeholder="Check-in Date*"
                  outlined
                  solo
                  flat
                  class="cart2-input"
                >
                  <template v-slot:prepend-inner>
                    <v-icon color="#D3D5D7" class="mr-3 ml-5 mt-1"
                      >mdil-calendar</v-icon
                    >
                  </template>
                </v-text-field> -->
                </v-col>
                <v-col cols="6" md="6" sm="12">
                  <v-menu
                    ref="checkOutMenu"
                    v-model="checkOutMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    class="cart2-input"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="dateUkFormat(form.checkout_time)"
                        hide-details="auto"
                        solo
                        outlined
                        flat
                        v-bind="attrs"
                        v-on="on"
                        placeholder="Check-out date"
                        class="cart2-input"
                      >
                        <template slot="prepend-inner">
                          <img
                            class="mr-3 ml-5 mt-1 calendar"
                            src="../../assets/images/calendar.svg"
                          />
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker
                      v-model="form.checkout_time"
                      no-title
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        :ripple="false"
                        text
                        color="primary"
                        @click="checkOutMenu = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        :ripple="false"
                        color="primary"
                        @click="$refs.checkOutMenu.save(form.checkout_time)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                  <!-- <v-text-field
                  v-model="form.checkout_time"
                  hide-details="auto"
                  placeholder="Check-out date"
                  outlined
                  solo
                  flat
                  class="cart2-input"
                >
                  <template v-slot:prepend-inner>
                    <v-icon color="#D3D5D7" class="mr-3 ml-5 mt-1"
                      >mdil-calendar</v-icon
                    >
                  </template>
                </v-text-field> -->
                </v-col>
                <v-col cols="12" md="12" sm="12">
                  <v-text-field
                    v-model="form.reservation_name"
                    hide-details="auto"
                    placeholder="Reservation Name"
                    outlined
                    solo
                    flat
                    class="cart2-input"
                  >
                    <template v-slot:prepend-inner>
                      <v-icon color="#D3D5D7" class="mr-3 ml-5 mt-1"
                        >mdil-account</v-icon
                      >
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
            </div>
            <div class="sec-details">
              <v-row>
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="form.shipping_address_1"
                    :rules="[
                      (v) => !!v || 'You must enter valid address to continue!',
                    ]"
                    hide-details="auto"
                    placeholder="Address"
                    outlined
                    solo
                    flat
                    class="cart2-input"
                  >
                    <template v-slot:prepend-inner>
                      <v-icon color="#D3D5D7" class="mr-3 ml-5 mt-1"
                        >mdil-map-marker</v-icon
                      >
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
            </div>
            <div>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="form.shipping_city_name"
                    :rules="[
                      (v) => !!v || 'You must enter city name to continue!',
                    ]"
                    hide-details="auto"
                    placeholder="City / Town*"
                    outlined
                    solo
                    flat
                    class="cart-input"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="form.shipping_state"
                    :rules="[(v) => !!v || 'You must enter state to continue!']"
                    hide-details="auto"
                    placeholder="State*"
                    outlined
                    solo
                    flat
                    class="cart-input"
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>
            <div>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-select
                    :rules="[
                      (v) => !!v || 'You must select country to continue!',
                    ]"
                    hide-details="auto"
                    :items="countryList"
                    placeholder="Select Country*"
                    item-text="name"
                    solo
                    flat
                    outlined
                    class="cart-input"
                    return-object
                    @change="selectedShippingCountry"
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="form.shipping_postcode"
                    :rules="[
                      (v) =>
                        !!v || 'You must enter postcode or zip to continue!',
                    ]"
                    hide-details="auto"
                    placeholder="Postcode / ZIP"
                    outlined
                    solo
                    flat
                    class="cart-input"
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>
          </template>
        </template>
        <div class="tandc">
          <v-checkbox
            v-model="form.agreed_to_t_and_c"
            :rules="[(v) => !!v || 'You must agree to continue!']"
            color="#070B32"
            :ripple="false"
            hide-details="auto"
            class="mt-0 pt-0"
          >
            <template v-slot:label>
              <div class="ml-5 tnc">
                Yes, I have read and agree to the Football Tickets Live
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <a target="_blank" href="Terms & Conditions" v-on="on">
                      Terms & Conditions
                    </a>
                  </template>
                </v-tooltip>
              </div>
            </template>
          </v-checkbox>
        </div>
        <div class="tandc">
          <v-checkbox
            color="#070B32"
            :ripple="false"
            v-model="form.create_account"
            hide-details="auto"
            class="mt-0 pt-0"
          >
            <template v-slot:label>
              <div class="ml-5 tnc">
                Sign add me to the Football Tickets Live newsletter list for
                offers, deals & updates
              </div>
            </template>
          </v-checkbox>
        </div>
      </div>
    </div>
    <div class="checkout-wrap pl-0 py-5" v-if="$vuetify.breakpoint.xsOnly">
      <FixtueDetailsCard :info="orderDetail" @updateQuantity="updateOrderQty" />
    </div>
    <div class="btn-proceed">
      <v-btn
        :ripple="false"
        @click="validate"
        class="btn-payment"
        elevation="0"
        :loading="loading"
      >
        Proceed to Payment
        <img
          class="ml-2 mt-1"
          src="../../assets/images/right-arrow.svg"
          alt="proceed_to_payment"
        />
      </v-btn>
    </div>
  </v-form>
</template>
<script>
import DateFormat from "../../mixins/Dates/ukFormat.js";
import FixtueDetailsCard from "@/components/PaymentMolecules/FixtureDetailsCard.vue";
export default {
  name: "CheckoutFormGuest",
  mixins: [DateFormat],
  props: {
    isAuthenticationError: {
      Type: Boolean,
      default() {
        return false;
      },
    },
    isLoggedIn: {
      Type: Boolean,
      default() {
        return false;
      },
    },
    orderDetail: {},
  },
  components: {
    FixtueDetailsCard,
  },
  data: (vm) => ({
    checkOutMenu: false,
    checkInMenu: false,
    valid: true,
    showLoginForm: false,
    showBillingAddressForm: false,
    showShippingAddressForm: false,
    officeAddress: null,
    form: {
      slug: vm.$route.params.orderSlug,
      first_name: null,
      last_name: null,
      email: null,
      phone: null,
      mobile: null,
      special_request: null,
      billing_address_1: null,
      billing_country_id: null,
      billing_country_name: null,
      billing_state: null,
      billing_city_name: null,
      billing_postcode: null,
      billing_address_type: null,
      address_first_name: null,
      address_last_name: null,
      shipping_address_1: null,
      shipping_country_id: null,
      shipping_country_name: null,
      shipping_state: null,
      shipping_city_name: null,
      shipping_postcode: null,
      shipping_address_type: null,
      company_name: null,
      company_start_time: null,
      company_end_time: null,
      boarding_name: null,
      checkin_time: null,
      checkout_time: null,
      reservation_name: null,
      agreed_to_t_and_c: false,
      create_account: false,
      billing_address_id: null,
      shipping_address_id: null,
    },
    loginFormValid: true,
    loginPayload: {
      email: "",
      password: "",
    },
    isShippingRequired: false,
    termsAndCondition: false,
    nameRules: [
      (v) => !!v || "Name is required",
      // (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
    ],
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    checkbox: false,
    countryList: [
      {
        name: "Germany",
        value: 2,
      },
      {
        name: "India",
        value: 6,
      },
      {
        name: "Spain",
        value: 3,
      },
      {
        name: "United Kingdom",
        value: 1,
      },
    ],
    addressTypeList: [
      {
        name: "Home",
        id: 1,
      },
      {
        name: "Office",
        id: 2,
      },
      {
        name: "Hotel",
        id: 3,
      },
      {
        name: "Hostel",
        id: 4,
      },
      {
        name: "Apartment",
        id: 5,
      },
    ],
  }),
  watch: {
    loggedInUser: {
      handler: function () {
        this.setLoggedInUser();
      },
      deep: true,
      immediate: true,
    },
    "form.billing_address_type": {
      handler: function () {
        this.resetCompany();
      },
      deep: true,
      immediate: true,
    },
    isShippingRequired: {
      handler: function () {
        this.resetShippingAddress();
        this.resetOtherShippingAddress();
      },
      deep: true,
      immediate: true,
    },
    "form.shipping_address_type": {
      handler: function () {
        this.resetOtherShippingAddress();
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    loading() {
      return this.$store.state.app.wait;
    },
    loggedInUser() {
      return this.$store.state.auth.user;
    },
    billingAddresses() {
      if (this.$store.state.users.usersAddress) {
        return this.$store.state.users.usersAddress.data;
      } else return [];
    },
    deliveryAddresses() {
      if (this.$store.state.users.usersAddress) {
        return this.$store.state.users.usersAddress.data;
      } else return [];
    },
  },
  methods: {
    resetCompany() {
      this.form.company_name =
        this.form.company_start_time =
        this.form.company_end_time =
          null;
    },
    resetShippingAddress() {
      this.form.address_first_name = null;
      this.form.address_last_name = null;
      this.form.shipping_address_1 = null;
      this.form.shipping_country_id = null;
      this.form.shipping_country_name = null;
      this.form.shipping_state = null;
      this.form.shipping_city_name = null;
      this.form.shipping_postcode = null;
      this.form.shipping_address_type = null;
    },
    resetOtherShippingAddress() {
      this.form.company_name = null;
      this.form.company_start_time = null;
      this.form.company_end_time = null;
      this.form.boarding_name = null;
      this.form.checkin_time = null;
      this.form.checkout_time = null;
      this.form.reservation_name = null;
    },
    selectBillingAddress(selected) {
      if (selected.id === this.form.billing_address_id)
        this.form.billing_address_id = null;
      else this.form.billing_address_id = selected.id;
    },
    selectShippingAddress(selected) {
      if (selected.id === this.form.shipping_address_id)
        this.form.shipping_address_id = null;
      else this.form.shipping_address_id = selected.id;
    },
    async validate() {
      if (!this.$refs.form.validate()) {
        return;
      }
      if (!this.form.billing_address_id && !this.form.billing_postcode) {
        alert("Please provide a billing address or create a billing address");
        return false;
      }
      if (
        this.isShippingRequired &&
        !this.form.shipping_address_id &&
        !this.form.shipping_postcode
      ) {
        alert("Please provide a Shipping address or create a shipping address");
        return false;
      }
      this.$emit("submitCheckoutForm", this.form);
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    selectedBillingCountry(item) {
      this.form.billing_country_name = item.name;
      this.form.billing_country_id = item.value;
    },
    selectedShippingCountry(item) {
      this.form.shipping_country_name = item.name;
      this.form.shipping_country_id = item.value;
    },
    async submitLoginReq() {
      if (!this.$refs.loginForm.validate()) {
        return;
      }
      this.$emit("signin", this.loginPayload);
    },
    setLoggedInUser() {
      if (!this.loggedInUser) return;
      this.form.first_name = this.loggedInUser.first_name;
      this.form.last_name = this.loggedInUser.last_name;
      this.form.email = this.loggedInUser.email;
      this.form.phone = this.loggedInUser.phone;
      this.form.mobile = this.loggedInUser.mobile;
    },

    async retrieveOrder() {
      // if (this.$store.getters.token && this.$store.state.auth.loggedInUser) {
      //   await this.$store.dispatch("orders/RETRIEVE_ORDER_FOR_LOGGEDIN_USER", {
      //     slug: this.orderSlug,
      //   });
      // }
      await this.$store.dispatch("orders/GET_GUEST_ORDER_DETAILS", {
        slug: this.orderSlug,
      });
    },

    async updateOrderQty(quantity) {
      if (this.$store.getters.token && this.$store.state.auth.loggedInUser) {
        await this.$store
          .dispatch("orders/UPDATE_ORDER_QUANTITY_FOR_REGISTERED_USER", {
            slug: this.orderSlug,
            quantity,
          })
          .finally(() => {
            this.retrieveOrder();
          });
      } else {
        await this.$store
          .dispatch("orders/UPDATE_ORDER_QUANTITY", {
            slug: this.orderSlug,
            quantity,
          })
          .then(() => {
            this.retrieveOrder();
          });
      }
    },
  },
};
</script>
<style></style>
